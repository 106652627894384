import React from "react";
import product1 from "../assets/products/1.png";
import product2 from "../assets/products/2.png";
import product3 from "../assets/products/3.png";
import product4 from "../assets/products/4.png";
import product5 from "../assets/products/5.png";
import product6 from "../assets/products/6.png";
import product7 from "../assets/products/7.png";
import product8 from "../assets/products/8.png";


const products = [
  {
    id: 1,
    name: "product 1",
    image: product1,
  },
  {
    id: 2,
    name: "product 2",
    image: product2,
  },
  {
    id: 3,
    name: "product 3",
    image: product3,
  },
  {
    id: 4,
    name: "product 4",
    image: product4,
  },
  {
    id: 5,
    name: "product 5",
    image: product5,
  },
  {
    id: 6,
    name: "product 6",
    image: product6,
  },
  {
    id: 7,
    name: "product 7",
    image: product7,
  },
  {
    id: 8,
    name: "product 8",
    image: product8,
  },
];
function Products() {
  return (
    <section id="products" className="font-ral container mx-auto py-12">
      <div className="flex flex-col items-center justify-center pb-8 space-y-3">
        <h1 className="text-3xl md:text-4xl uppercase font-bold text-center">
          Products
        </h1>
      
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 space-y-8 gap-4 md:space-y-0">
        {products.map((product) =>{
          return (
            <div className="max-w-sm" key={product.id}>
              <div className="flex flex-col items-center space-y-2">
                <div>
                  <img src={product.image} className="h-96 w-80 object-cover rounded-xl" alt=""/>
                </div>
              </div>
            </div>
          );
        })}

        </div>
    </section>
  );
}

export default Products;
