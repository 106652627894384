import React, { useState } from "react";
import { TbAlignRight } from "react-icons/tb";
import { TfiClose } from "react-icons/tfi";

function Navbar() {
  const contact = "+977-9860639388";
  const companyName="S & R Shoe Centre"
  const elements = [
    { id: 1, name: "Home", link: "#" },
    { id: 2, name: "Products", link: "#products" },
    { id: 3, name: "Services", link: "#services" },
    { id: 5, name: "Testimonials", link: "#testimonials" },
    { id: 4, name: "Contact", link: "#contact" },
  ];
  const [show, setShow] = useState(false);
  const handleClick = () => {
    setShow(!show);
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      <header className="bg-secondary">
        <nav className="font-ral container mx-auto flex items-center justify-between py-4 px-3 md:px-0">
          <div className="logo text-dark font-semibold capitalize">{companyName}</div>
          <div className="hidden md:block space-x-12">
            {elements.map(function (element) {
              return (
                <a
                  className="text-dark hover:text-slate-300"
                  href={element.link}
                  key={element.id}
                >
                  {element.name}
                </a>
              );
            })}
          </div>
          <div className="hidden md:block bg-primary text-white px-4 py-2  cursor-pointer">
            <a href={`tel:${contact}`}> {contact}</a>
          </div>
          <div className="block md:hidden" onClick={handleClick}>
            <TbAlignRight className="text-2xl" />
          </div>
        </nav>
        {show && (
          <div className="animate__animated animate__fadeInRight font-poppins fixed right-0 top-0 md:hidden z-40">
            <div className="flex flex-col bg-ternary items-center justify-center w-[70vw] h-96 space-y-6">
              <TfiClose
                className="fixed top-0 right-0 m-4 text-2xl"
                onClick={handleClose}
              />
              {elements.map(function (element) {
                return (
                  <a
                    className="hover:text-slate-500 text-lg"
                    href={element.link}
                    key={element.id}
                  >
                    {element.name}
                  </a>
                );
              })}
            </div>
          </div>
        )}
      </header>
    </>
  );
}

export default Navbar;
