import React from "react";
import { GiRunningShoe,GiClothes } from "react-icons/gi";
import { BsCalendar4Event } from "react-icons/bs";
import { SiAltiumdesigner } from "react-icons/si";
function Services() {
  const elements = [
    {
      id: 1,
      name: "Traditional Shoes, Slippers, and Clothing:",
      content:
        "We specialize in the import/export of traditional shoes, slippers, and clothing from around the world.",
      icon: <GiRunningShoe />,
    },
    {
      id: 2,
      name: "Sales of Shoes and Clothing",
      content:
        "We have a wide selection of shoes and clothing available for sale at affordable prices.",
        icon: <GiClothes />,
    },
    {
      id: 3,
      name: "Event Organization",
      content:
        "We provide customized event organization and execution services for fashion shows, product launches, and more.",
        icon: <BsCalendar4Event />,
    },
    {
      id: 4,
      name: "Designer Products",
      content:
        "We offer the import/export of high-quality designer products, including clothing and shoes.",
        icon: <SiAltiumdesigner />,
    },
  ];
  return (
    <section id="services" className="font-ral py-4 md:py-16 mb-6 px-3 md:px-0">
      <div className="container mx-auto">
        <div className="flex flex-col items-center justify-center pb-8 space-y-3">
          <h1 className="text-3xl md:text-4xl uppercase font-bold text-center">
            Services
          </h1>
          <p className="w-full md:w-2/3 text-center">
            We provide a wide range of services to meet your needs. Whether
            you're looking to import or export shoes and clothing, organize
            events, or purchase designer products, we've got you covered. Our
            team of experts is dedicated to providing you with the best service
            possible.
          </p>
        </div>

        <div className="grid grid-cols-1 gap-5 mt-12 sm:grid-cols-4 lg:mt-20 lg:gap-x-12">
          {elements.map(function (element) {
            const { id, name, content,icon } = element;
            return (

          <div key={id} className="transition-all duration-200 bg-white hover:shadow-lg">
            <div className="py-10 px-9">
             <p className="text-6xl">{icon}</p>
              <h3 className="mt-8 text-lg font-semibold text-black">
                {name}
              </h3>
              <p className="mt-4 text-base text-gray-700">
                {content}
              </p>
            </div>
          </div>

            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Services;
